import { Component, OnInit, ViewChild } from '@angular/core'; 
import { HttpEventType } from '@angular/common/http';
declare var $ : any; 


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit { 

  constructor() {
    
  }

  ngOnInit() { 

  }  
  
}
