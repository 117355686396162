import { NgModule }     from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule }    from '@ng-bootstrap/ng-bootstrap';

//Components
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
	exports: [
		CommonModule,
		SidebarComponent
	],
	imports: [
		RouterModule,
        CommonModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule
	],
	declarations: [
		SidebarComponent
	]
})
export class SharedModule { }