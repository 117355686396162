import { environment } from '../../../environments/environment';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
declare const window : any;

//Services
import { AuthService }   from "../../shared/auth/auth.service";
import { LoaderService } from "../../shared/service/loader.service";

@Component({
    selector    : 'app-sidebar',
    templateUrl : './sidebar.component.html',
    styleUrls   : ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {

  @ViewChild('generalSidebar', { static : true })
  generalSidebar : ElementRef<HTMLElement>;

  logo = environment.logo;
  user;

	constructor (
      public authService    : AuthService,
      private loaderService : LoaderService
    ) {

		this.user = authService.user;
	}

	ngOnInit() {
      var url = window.location.href;
      //Si esta activo el componente visor pdf
      if (url.includes('/libros/visor/pdf/')) {
          //Ocultar sidebar general
          this.generalSidebar.nativeElement.style.display = 'none';
      } else {
          //Mostrar sidebar general
          this.generalSidebar.nativeElement.style.display = 'block';
      }
	}

  recargarPagina() {
      //Mostrar spinner (loading)
      this.loaderService.show({show : true});
      //Recagar
      window.location = window.location.href.split('#')[0] + '#loaded';
      window.location.reload(true);
  }
}
