<app-sidebar *ngIf="layoutService.showSide"></app-sidebar>

<div class="container-fluid">
	<div class="row justify-content-center" [ngStyle]="(layoutService.showSide) ? {'margin-top':'80px'} : ((layoutService.showSecondarySide) ? {'margin-top':'65px'} : '')">
		<div class="col-sm-12">
			<router-outlet></router-outlet>
		</div>
	</div>
	
</div>
