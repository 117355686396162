<div #generalSidebar id="generalSidebar" class="sidebar-header">
	<div class="container">
		<div class="row">
        <div class="col-2 col-sm-2">
          <img [src]="logo" alt="" style="padding-top: 0px; width: 125px;">
        </div>

        <div class="col-8 col-sm-8">
          <div class="row justify-content-center">
          </div>
        </div>

        <div class="col-2 col-sm-2">
          <div class="ml-auto mr-3">
              <div class="profile" style="margin-top: 14px;" [ngStyle]="{'background-image':'url( assets/img/profile.png' }"
                [ngbPopover]="popContent" [popoverTitle]="popTitle" triggers="manual"
                #p1="ngbPopover" (click)="p1.open()" popoverClass="pop">
              </div>

              <ng-template #popTitle let-language="language">
                <div style="padding: 10px;" *ngIf="user">
                  <div class="profile-full" [ngStyle]="{'background-image':'url( assets/img/profile.png' }">
                  </div>
                  <div style="display: inline-block; vertical-align: top;  padding: 10px 20px;">
                    <p class="mb-0">{{authService.user.firstname+' '+authService.user.lastname}}</p>
                    <p style="font-weight: 200;" *ngIf="authService.user?.roles">{{authService.user.roles[0].name}}</p>
                  </div>
                </div>
              </ng-template>

              <ng-template #popContent let-greeting="greeting">
                <!--
                <div style="padding: 10px;">
                  <span class="text-danger" style="cursor: pointer;">
                    <i class="fa fa-close mr-1" aria-hidden="true" alt="ver"></i>
                    Cerrar sesión
                  </span>
                </div>
                -->
              </ng-template>
          </div>
        </div>

		</div>
	</div>
</div>
