import { Injectable } from '@angular/core';
import {   HttpHandler, HttpRequest, HttpEvent, HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(private authService:AuthService){}

	intercept(req: HttpRequest<any>, next:HttpHandler):
		Observable<HttpEvent<any>>{
			//var authReq; 
			/*authReq = req.clone({
		      	headers:req.headers.set('Content-Type', 'application/json')

		      	//headers:req.headers.set('Content-Type','application/json')
		      	//.set('Content-Type','multipart/form-data')
		      	//.set('Content-Length','<calculated when request is sent>')
		      })*/

			let token = this.authService.token;
			
			 if(token!=null){
			 	var authReq; 

		      	authReq = req.clone({
			      	headers:req.headers.set('Authorization',token)
			      	//.set('Content-Type','multipart/form-data')
			      	//.set('Content-Length','<calculated when request is sent>')
			    })
		      
		      
		      return next.handle(authReq);
		    } 

		    authReq = req.clone({
			      	headers:req.headers.set('Authorization',token)
			      	//.set('Content-Type','multipart/form-data')
			      	//.set('Content-Length','<calculated when request is sent>')
			    })

			return next.handle(req);
	}
	
} 