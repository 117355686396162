import { BrowserModule           } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule                } from '@angular/core';
import { FormsModule             } from '@angular/forms';
import { DragDropModule          } from '@angular/cdk/drag-drop';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppComponent     } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule     } from "./shared/shared.module";
import { NgbModule        } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner"; 

//Components
import { FullLayoutComponent    } from "./layouts/full/full-layout.component";
import { LoaderSpinnerComponent } from "./shared/loader-spinner/loader-spinner.component";

//Services
import { AuthService         } from "./shared/auth/auth.service";
import { TokenInterceptor    } from './shared/auth/interceptors/token.interceptor';
import { LoaderService       } from "./shared/service/loader.service";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment         } from '../environments/environment';


@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        LoaderSpinnerComponent,
    ],
    imports: [
        SharedModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule, 
        FormsModule,
        NgbModule,
        HttpClientModule,
        NgxSpinnerModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        DragDropModule 
    ],
    providers: [
        AuthService,
        LoaderService,
        {provide: HTTP_INTERCEPTORS, useClass:TokenInterceptor, multi:true},
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
