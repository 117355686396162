<!--<ngx-spinner
bdColor = "rgba(51, 51, 51, 0.8)"
size = "large"
[color] = "baseApiService.colorLoader"
type = "ball-spin-clockwise"
></ngx-spinner>-->


<ngx-spinner
	bdColor = "rgba(0, 0, 0, 0.6)" 
	size = "default" 
	color = "#fff" 
	type = "ball-clip-rotate" 
	[fullScreen] = "true">
		
</ngx-spinner>
