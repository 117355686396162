import { LayoutService }     from '../../shared/service/layout.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector    : 'app-full-layout',
    templateUrl : './full-layout.component.html'
})

export class FullLayoutComponent implements OnInit {

	constructor(public layoutService:LayoutService) {

	}

	ngOnInit() {

	}
}
