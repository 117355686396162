import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
const appRoutes: Routes = [
  /*{
    path: '',
    redirectTo: 'libros',
    pathMatch: 'full',
  },*/
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES},
  //{ path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}