import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize }   from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

import Dexie           from 'dexie';
import { User }        from './user';
import { environment } from '../../../environments/environment';

//Servicios
import { LoaderService }        from "../../shared/service/loader.service";
import { OnlineOfflineService } from 'src/app/services/online-offline.service';

@Injectable()
export class AuthService {

	private db        : Dexie;
	private userTable : Dexie.Table<User, any> = null;

	constructor(
      private http:HttpClient,
      private loaderService:LoaderService,
      private onlineOfflineService : OnlineOfflineService
	) {

		this.listenConnectionStatus();
		this.initIndexDB();

	}

	private showLoader(id): void {
      this.loaderService.show(id);
  }

  private onEnd(id): void {
      this.loaderService.hide(id);
  }

	private _user  : User;
	private _token : string;

	listenConnectionStatus() {
		this.onlineOfflineService.getConnetionStatus.subscribe(online => {

		});
	}

	initIndexDB() {
		this.db = new Dexie('users_db');
		this.db.version(1).stores({
			user : 'id'
		});
		this.userTable = this.db.table('user');
	}

	public get user():User {
  		if (this._user != null) {
			return this._user;

  		} else if (this._user == null && sessionStorage.getItem('user') != null) {
  			this._user = JSON.parse(sessionStorage.getItem('user')) as User;
			return this._user;
		}

  		return new User;
    };

	public get token():string {
	  	if (this._token != null) {
			return this._token;

	  	} else if(this._token == null && sessionStorage.getItem('token') != null) {
	  		this._token = sessionStorage.getItem('token');
	  		return this._token;
	  	}

	  	return null;
	};

	saveUser(user) {
	  	if (user!= null) {
	  		this._user = user;
			sessionStorage.setItem('user',JSON.stringify(this._user));

	  	} else {
	  		this._user = null;
	  		sessionStorage.removeItem('user');
	  	}
	}

	async saveUserIndexedDB(user : User) {
		try {
			await this.userTable.delete(user.id);
			await this.userTable.add(user);
			const localUser : User[] = await this.userTable.toArray();

		} catch (error) {
			console.log('ERROR USUARIO NO GUARDADO EN INDEXED-DB', error);
		}
	}

	saveToken(accessToken:string) {
	  	this._token = accessToken;
	  	sessionStorage.setItem('token',this._token);
	}

	isAuthenticated():boolean {
	  	if (this._user!= null) {
	  		return true;
	  	}
	  	return false;
	}

	hasRole(role:string):boolean {
      if (!this.user.roles) {
        return false;

      } else {
        for (var i = 0; i < this.user.roles.length; i++) {
          if (this.user.roles[i].name == role) {
            return true;
          }
        }
      }
	  	return false;
	}

	login(token):Observable<any> {
		  this.showLoader({"id":100001, "service":"AuthService", "function":"login"});
	  	const urlEnpoint = environment.urlBackEndBase +'api/user/'+token;

	  	return this.http.get<any>(urlEnpoint).pipe( finalize(()=> this.onEnd(100001)) );
	}
}
