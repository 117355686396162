import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription }      from 'rxjs';
import { LoaderService }     from '../service/loader.service';
import { LoaderState }       from '../service/data/loader';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-loader-spinner',
  templateUrl: 'loader-spinner.component.html',
  styleUrls: ['loader-spinner.component.scss']
})

export class LoaderSpinnerComponent implements OnInit {
    show = false;
    private subscription : Subscription;

    constructor (
        private loaderService : LoaderService,
        private spinner       : NgxSpinnerService
    ) { }

    ngOnInit() {

    this.subscription = this.loaderService.loaderState
        .subscribe((state: LoaderState) => {
            let html = document.querySelector('html');  
            //Si existen datos          
            if (this.loaderService.loaderData.length > 0) {
                var last = this.loaderService.loaderData.length - 1;
                if (this.loaderService.loaderData[last].show == false) {
                    //Ocultar spinner
                    this.spinner.hide();
                    return;
                } 
                //Mostrar spinner
                this.spinner.show();  
            } else {
                //Ocultar spinner
                this.spinner.hide();
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
