export class User{
	id:number;
	username:string;
	password:string;
	auth:string;
	confirmed:string;
	policyagreed:any;
	deleted:any;
	suspended:any;
	mnethostid:any;
	idnumber:any;
	firstname:any;
	lastname:any;
	email:any;
	emailstop:any;
	icq:any;
	skype:any;
	yahoo:any;
	aim:any;
	msn:any;
	phone1:any;
	phone2:any;
	institution:any;
	department:any;
	address:any;
	city:any;
	country:any;
	lang:any;
	calendartype:any;
	theme:any;
	timezone:any;
	firstaccess:any;
	lastaccess:any;
	lastlogin:any;
	currentlogin:any;
	lastip:any;
	secret:any;
	picture:any;
	url:any;
	description:any;
	descriptionformat:any;
	mailformat:any;
	maildigest:any;
	maildisplay:any;
	autosubscribe:any;
	trackforums:any;
	timecreated:any;
	timemodified:any;
	trustbitmask:any;
	imagealt:any;
	lastnamephonetic:any;
	firstnamephonetic:any;
	middlename:any;
	alternatename:any;
	roles:any;
	
}