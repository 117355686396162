// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    credencialesBackEndBase : "root:root",
    production              : true,
    logo                    : 'assets/img/logoc.jpeg',

    urlBackEndBaseLocal     : 'http://localhost:8000/',
    urlBackEndLocalAux      : 'http://localhost:8100/',
    rootSupportFile         : 'http://localhost:4500/assets/res/root/source/',    

    //----------------------------------------------------------------------------------------------------

    // urlFrontendBase         : 'http://localhost:4200/',
    // urlBackEndBase          : 'http://localhost:8000/',
    // urlCorefoConverter      : 'http://localhost:4200/teacher-resource-converter',    

    // urlFrontendBase         : 'https://demolibrodigital.corefo.com/',
    // urlBackEndBase          : 'https://demoapiaulavirtual.corefo.com/',
    // urlCorefoConverter      : 'https://recursosdocentes.corefo.com', 

    urlFrontendBase         : 'https://librodigital.corefo.com/',
    urlBackEndBase          : 'https://apiaulavirtual.corefo.com/',
    urlCorefoConverter      : 'https://recursosdocentes.corefo.com', 

    //----------------------------------------------------------------------------------------------------

    googleSearchUrl         : 'https://www.googleapis.com/customsearch/v1',
    googleSearchApiKey      : 'AIzaSyBw7C_1rhsTaXYYx2Q-LimI6-5W3OY50kw',
    googleSearchEngineId    : 'f19ac864ac464426c',

    //----------------------------------------------------------------------------------------------------

    barCodeSource           : 'https://qrcode.tec-it.com/API/QRCode'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
