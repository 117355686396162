import { Injectable } from '@angular/core';
import { Subject,Observable } from 'rxjs';

@Injectable({
    providedIn : 'root'
})
export class LayoutService {
	//****************cramirez************************
    // Inicio mostrar side
	public showSide          : boolean;
	public showSecondarySide : boolean;
	public showSide$         : Observable<any>;
	private showSideSubject  = new Subject<any>();

	constructor() {
		this.showSide$ = this.showSideSubject.asObservable();
    	this.showSide = true;
	}

	public setshowSide(showSide, showSecondarySide = false) {
	    this.showSideSubject.next(showSide);
		this.showSide          = showSide;
		this.showSecondarySide = showSecondarySide;
	}

	public getshowSide(){
	    return this.showSide;
	}

    // Fin mostrar side
    //================================================
}